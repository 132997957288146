import React, { Component } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faComment } from '@fortawesome/free-solid-svg-icons';

/**
 * Composant Recherche contenant une barre (avec auto-complétion) et un bouton de recherche
 */
class Search extends Component {
    /**
     * Toutes les variables dont a besoin le Composant et qui seront modifiées à travers l'application (variables d'état)
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.onKeyDown = this.onKeyDown.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.state = {
            results: [],
            searchResult: true,
            value: props.initialValue || []
        }
    }

    componentDidMount() {
        fetch('/api/connect/status')
        .then(response => {
            if (response.status !== 200) {
                // console.log('User pbbt pas encore connecté');
                return response.text();
            }
            return response.json();
        })
        .then(content => {
            if (content.connectStatus === 2) {
                this.setState({ showIa: true });
            }
          })
          .catch(error => console.error('Error fetching data:', error));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.initialValue !== this.props.initialValue) {
            this.setState({ value: this.props.initialValue });
        }
    }

    /**
     * Fonction déclenchée au moment de la saisie d'un mot-clé dans la barre de recherche
     * @param {*} e : événement onInputChange
     * @param {*} value : valeur saisie par l'utilisateur dans la barre de recherche
     */
    onValueChangeSearch(e) {
        const value = e.target.value; // Accéder à la valeur de l'entrée
        //Appel de la fonction définie dans l'attribut onValueChange présente dans la page Home.js
        this.props.onValueChange(e, value);
    };

    onKeyDown(e) {
        if (e.key === 'Enter') {
            this.handleClick(e);
        }
    }
    
    /**
     * Fonction déclenchée au moment de la recherche de fiches Mécathèque grâce au bouton de recherche
     * @param {*} e :  événement onClick
     */
    handleClick(e) {
        //Appel de la fonction définie dans l'attribut handleClick présente dans la page Home.js
        this.props.handleClick();
    }

    handleClickIA(e) {
        window.open('https://mecatheque.ai.cetim.fr/', '_blank');
    };

    /**
     * Fonction déclenchée au moment de la sélection de mots-clés
     * @param {*} e : évement onChange
     * @param {*} value : liste des mots-clés sélectionnées
     */
    selectValue(e, value) {
        //Appel de la fonction définie dans l'attribut selectValue présente dans la page Home.js
        this.props.selectValue(value);
    }

    /**
     * Rendu du Composant de recherche
     * @returns : template HTML avec les différents appels aux fonction JS
     */
    render() {
        // Permet d'accepter la case (sensitive : false, insensitive : true)
        // A mettre dans le composant Autcomplete ; filterOptions={filterOptions}
        const filterOptions = createFilterOptions({
            ignoreAccents: true,
            ignoreCase: true,
            //limit: 7,
        });

        return (
            <div className='col-sm-12 col-md-6 d-flex align-items-center'>
            <Autocomplete
                id="free-solo-demo"
                freeSolo
                onInputChange={this.onValueChangeSearch.bind(this)}
                onChange={this.selectValue.bind(this)}
                onKeyDown={this.onKeyDown}
                filterOptions={filterOptions}
                multiple
                value={this.state.value} // Utiliser la valeur de l'état
                sx={{ width: 500 }}
                options={this.props.resultsES.map(option => option._source.expression)}
                renderInput={(params) => <TextField {...params} label="Rechercher" sx={{
                "& label.Mui-focused": {
                    color: '#CA0538'
                },
                "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                    borderColor: '#CA0538'
                    }
                }
                }} />}
            />
            <button className="btn btn-primary bouton-recherche" onClick={this.handleClick.bind(this)} style={{ minWidth: 50 }}>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
            {this.state.showIa && (
                <button style={{ marginLeft: 10, minWidth: 50 }} className="btn btn-primary bouton-recherche" onClick={this.handleClickIA.bind(this)}>
                <svg style={{ marginTop: -5 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path style={{ fill: 'white' }} d="M123.6 391.3c12.9-9.4 29.6-11.8 44.6-6.4c26.5 9.6 56.2 15.1 87.8 15.1c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 32 12.4 62.8 35.7 89.2c8.6 9.7 12.8 22.5 11.8 35.5c-1.4 18.1-5.7 34.7-11.3 49.4c17-7.9 31.1-16.7 39.4-22.7zM21.2 431.9c1.8-2.7 3.5-5.4 5.1-8.1c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6c-15.1 6.6-32.3 12.6-50.1 16.1c-.8 .2-1.6 .3-2.4 .5c-4.4 .8-8.7 1.5-13.2 1.9c-.2 0-.5 .1-.7 .1c-5.1 .5-10.2 .8-15.3 .8c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4c4.1-4.2 7.8-8.7 11.3-13.5c1.7-2.3 3.3-4.6 4.8-6.9l.3-.5z"/></svg>
                </button>
            )}
            </div>
        )
    }
}

export default Search;