import React, { Component } from 'react';

import "./styles.css";
import logoCti from '../../assets/images/logo-cti.png';
import FooterMecalliance from './FooterMecalliance';

import { env } from '../../env'

// Fonction récursive pour afficher les liens
const LinkTree = ({ links }) => {
  return (
    <ul>
      {links.map((link, index) => (
        <li 
        key={index} 
        className={link.child_menu ? 'has-children' : ''}
        >
          <a href={link.link}>{link.title}</a>
          {link.child_menu && <LinkTree links={link.child_menu} />}
        </li>
      ))}
    </ul>
  );
};

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
          json: null,  // On initialise avec null pour savoir si les données sont chargées
          error: null  // Pour gérer les erreurs
        };
    }

    componentDidMount() {
        this.getFooter()
            .then(data => this.setState({ json: data.data })) // On récupère juste le contenu utile
            .catch(error => this.setState({ error }));
    }
      
    render() {
        const { json, error } = this.state;
        if (error) {
            return <div></div>;
        }
        if (json === null) {
            return <div></div>; // Affichage pendant le chargement
        }

        return (
            <footer className='footer-container row'>
                <div className='col-sm-12 col-md-3' style={{ padding: 30 }}>
                  <img src={logoCti} alt="Logo CTI" style={{ width: 130 }} />
                  <span style={{ fontStyle: 'italic', fontSize: '80%', marginTop: 30, lineHeight: 1 }}>Le Cetim est labellisé Carnot et membre du réseau CTI</span>
                </div>
                <div className='col-sm-12 col-md-3'>
                  <LinkTree links={json['column1-footer']} />
                </div>
                <div className='col-sm-12 col-md-3'>
                  <LinkTree links={json['column2-footer']} />
                </div>
                <div className='col-sm-12 col-md-3 column3-footer'>
                  <LinkTree links={json['column3-footer']} />
                </div>
                <FooterMecalliance></FooterMecalliance>
                <div class="footer-end">
                  <div class="footer-cell">
                      <a class="post-footer-column-link" href="https://www.cetim.fr/informations-pratiques" title="Informations pratiques">Informations pratiques</a>
                  </div>
                  <div class="footer-cell">
                      <a class="post-footer-column-link" href="https://www.cetim.fr/mentions-legales" title="Mentions légales">Mentions légales</a>
                  </div>
                  <div class="footer-cell">
                      <a class="post-footer-column-link" href="https://www.cetim.fr/donnees-personnelles" title="Données personnelles">Données personnelles</a>
                  </div>
                  <div class="footer-cell">
                      <a class="post-footer-column-link" href="https://www.cetim.fr/conditions-generales-de-vente-et-de-prestations" title="Conditions générales de vente">Conditions générales de vente</a>
                  </div>
                  <div class="footer-cell">
                      <a class="post-footer-column-link" href="https://www.cetim.fr/avis-d-achat" title="Cookies">Avis d'achat</a>
                  </div>
                </div>
                <div class="footer-end">{env.REACT_APP_VERSION}</div>
            </footer>
        );
    }

    getFooter() {
      return fetch('/__internal/footer/?outputype=raw',
        {
          method: 'GET' ,
          headers: {
            'Accept': 'application/json'
          },
        })
        .then(response => {
          if (response.status >= 400) {
            throw new Error("Server responds with error! " + response.statusText);
          }
          return response.json();
        })
        .catch(error => {
          console.error("Fallback on hardcoded footer because of error while fetching footer:", error);
          return ({
            "data": {
              "column1-footer": [
                {
                  "id": 1212,
                  "menu_id": 0,
                  "link": "https://www.cetim.fr/prestations/",
                  "title": "Solutions et prestations",
                  "child_menu": [
                    {
                      "id": 1213,
                      "menu_id": 100,
                      "link": "https://www.cetim.fr/prestations/ingenierie-produits-procedes/",
                      "title": "Ingénierie produits/procédés"
                    },
                    {
                      "id": 1214,
                      "menu_id": 101,
                      "link": "https://www.cetim.fr/prestations/essais-controles-mesures",
                      "title": "Essais – contrôles – mesures"
                    },
                    {
                      "id": 1215,
                      "menu_id": 102,
                      "link": "https://www.cetim.fr/prestations/conseil-et-expertises",
                      "title": "Conseil et Expertises"
                    },
                    {
                      "id": 1386,
                      "menu_id": 103,
                      "link": "https://www.cetim.fr/expertises/l-analyse-de-defaillance/",
                      "title": "Analyse de défaillance"
                    },
                    {
                      "id": 1216,
                      "menu_id": 104,
                      "link": "https://www.cetim.fr/temoignages",
                      "title": "Témoignages Clients"
                    }
                  ]
                },
                {
                  "id": 1217,
                  "menu_id": 1,
                  "link": "https://www.cetim.fr/formation",
                  "title": "Formations Cetim Academy®",
                  "child_menu": [
                    {
                      "id": 1218,
                      "menu_id": 100,
                      "link": "https://www.cetim.fr/formation/Formation",
                      "title": "Par thématiques"
                    },
                    {
                      "id": 1219,
                      "menu_id": 101,
                      "link": "https://www.cetim.fr/formation/Briques-technologiques",
                      "title": "Par briques technologiques"
                    },
                    {
                      "id": 1220,
                      "menu_id": 102,
                      "link": "https://www.cetim.fr/formation/Chaines-de-valeur",
                      "title": "Chaînes de valeur"
                    },
                    {
                      "id": 1221,
                      "menu_id": 103,
                      "link": "https://www.cetim.fr/formation/Formations-qualifiantes-certifiantes",
                      "title": "Qualifiantes - Certifiantes"
                    },
                    {
                      "id": 1222,
                      "menu_id": 104,
                      "link": "https://www.cetim.fr/formation/Parcours-de-specialisation",
                      "title": "Parcours de spécialisation"
                    }
                  ]
                },
                {
                  "id": 1223,
                  "menu_id": 2,
                  "link": "https://www.cetim.fr/mecatheque",
                  "title": "Mécathèque",
                  "child_menu": [
                    {
                      "id": 1224,
                      "menu_id": 100,
                      "link": "https://www.cetim.fr/mecatheque/Toute-la-richesse-des-etudes",
                      "title": "Résultats d’étude"
                    },
                    {
                      "id": 1225,
                      "menu_id": 101,
                      "link": "https://www.cetim.fr/mecatheque/Rechercher/Toute-la-base",
                      "title": "Base documentaire"
                    },
                    {
                      "id": 1226,
                      "menu_id": 102,
                      "link": "https://www.cetim.fr/mecatheque/start-ups",
                      "title": "Annuaire des startups"
                    }
                  ]
                },
                {
                  "id": 1227,
                  "menu_id": 3,
                  "link": "https://www.cetim.fr/logiciels",
                  "title": "Logiciels",
                  "child_menu": [
                    {
                      "id": 1260,
                      "menu_id": 100,
                      "link": "https://www.cetim.fr/logiciels/?category=Logiciels%20m%C3%A9tiers",
                      "title": "Logiciels métiers"
                    },
                    {
                      "id": 1261,
                      "menu_id": 101,
                      "link": "https://www.cetim.fr/logiciels/?category=Logiciels%20de%20calcul",
                      "title": "Logiciels de calcul"
                    },
                    {
                      "id": 1262,
                      "menu_id": 102,
                      "link": "https://www.cetim.fr/logiciels/?category=Aide%20au%20chiffrage",
                      "title": "Aide au chiffrage"
                    },
                    {
                      "id": 1263,
                      "menu_id": 103,
                      "link": "https://www.cetim.fr/logiciels/?category=Bases%20de%20donn%C3%A9es",
                      "title": "Bases de données"
                    }
                  ]
                }
              ],
              "column2-footer": [
                {
                  "id": 1228,
                  "menu_id": 4,
                  "link": "",
                  "title": "Agréments et reconnaissances QSE",
                  "child_menu": [
                    {
                      "id": 1229,
                      "menu_id": 100,
                      "link": "https://www.cetim.fr/reconnaissances/certification-qualite/",
                      "title": "Certifications qualité"
                    },
                    {
                      "id": 1230,
                      "menu_id": 101,
                      "link": "https://www.cetim.fr/reconnaissances/cofrac-etalonnage/",
                      "title": "Cofrac Étalonnage"
                    },
                    {
                      "id": 1231,
                      "menu_id": 102,
                      "link": "https://www.cetim.fr/reconnaissances/cofrac-essai/",
                      "title": "Cofrac Essai"
                    },
                    {
                      "id": 1232,
                      "menu_id": 103,
                      "link": "https://www.cetim.fr/reconnaissances/mase/",
                      "title": "MASE"
                    },
                    {
                      "id": 1233,
                      "menu_id": 104,
                      "link": "https://www.cetim.fr/reconnaissances/notifications-ce/",
                      "title": "Notifications CE"
                    },
                    {
                      "id": 1234,
                      "menu_id": 105,
                      "link": "https://www.cetim.fr/reconnaissances/agrements-internationaux/",
                      "title": "Agréments internationaux"
                    },
                    {
                      "id": 1235,
                      "menu_id": 106,
                      "link": "https://www.cetim.fr/reconnaissances/agrement-ministeriel/",
                      "title": "Agrément ministériel"
                    },
                    {
                      "id": 1236,
                      "menu_id": 107,
                      "link": "https://www.cetim.fr/reconnaissances/certifications-cofrend/",
                      "title": "Certifications Cofrend"
                    }
                  ]
                },
                {
                  "id": 1238,
                  "menu_id": 5,
                  "link": "",
                  "title": "Appui à l'industrie",
                  "child_menu": [
                    {
                      "id": 1237,
                      "menu_id": 100,
                      "link": "https://www.cetim.fr/programmes-regionaux",
                      "title": "Programmes régionaux"
                    }
                  ]
                },
                {
                  "id": 1009,
                  "menu_id": 6,
                  "link": "",
                  "title": "Qui sommes-nous ?",
                  "child_menu": [
                    {
                      "id": 1010,
                      "menu_id": 100,
                      "link": "https://www.cetim.fr/presentation",
                      "title": "Le Cetim en bref"
                    },
                    {
                      "id": 1707,
                      "menu_id": 101,
                      "link": "https://www.cetim.fr/nos-valeurs/",
                      "title": "Nos valeurs"
                    },
                    {
                      "id": 1011,
                      "menu_id": 102,
                      "link": "https://www.cetim.fr/gouvernance",
                      "title": "Gouvernance"
                    }
                  ]
                }
              ],
              "column3-footer": [
                {
                  "id": 993,
                  "menu_id": 7,
                  "link": "https://www.cetim.fr/agenda",
                  "title": "AGENDA"
                },
                {
                  "id": 994,
                  "menu_id": 8,
                  "link": "https://www.cetim.fr/actualites",
                  "title": "ACTUALITÉS"
                },
                {
                  "id": 995,
                  "menu_id": 9,
                  "link": "https://www.youtube.com/user/CetimFrance",
                  "title": "VIDÉOS"
                },
                {
                  "id": 996,
                  "menu_id": 10,
                  "link": "https://www.cetim.fr/implantations",
                  "title": "IMPLANTATIONS"
                },
                {
                  "id": 997,
                  "menu_id": 11,
                  "link": "https://www.cetim.fr/nous-rejoindre",
                  "title": "NOUS REJOINDRE"
                },
                {
                  "id": 998,
                  "menu_id": 12,
                  "link": "#",
                  "title": "NOUS CONTACTER"
                }
              ]
            },
            "itemsCount": 3,
            "status": "success",
            "code": 200
          });
        })
    }

}

export default Footer;
