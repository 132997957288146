import React, { useEffect } from 'react';

const Ia = ({ show, email }) => {
  useEffect(() => {
    // Charger le script CryptoJS
    const scriptCrypto = document.createElement('script');
    scriptCrypto.src = 'https://cdnjs.cloudflare.com/ajax/libs/crypto-js/3.1.9-1/crypto-js.js';
    scriptCrypto.async = true;
    document.body.appendChild(scriptCrypto);

    scriptCrypto.onload = () => {
      // console.log('Script CryptoJS chargé.');

      // Charger le script Chainlit
      const scriptChainlit = document.createElement('script');
      scriptChainlit.src = 'https://mecatheque.ai.cetim.fr/copilot/index.js';
      scriptChainlit.async = true;
      document.body.appendChild(scriptChainlit);

      scriptChainlit.onload = () => {
        // console.log('Script Chainlit chargé.');

        // Ajouter l'écouteur pour chainlit-call-fn
        window.addEventListener('chainlit-call-fn', (e) => {
          const { name, args, callback } = e.detail;
          // console.log(`Fonction appelée : ${name}, avec les arguments :`, args);
          callback(`You sent: ${args.msg}`);
        });

        // Générer le token et configurer Chainlit
        function base64url(source) {
          let encodedSource = window.CryptoJS.enc.Base64.stringify(source);
          encodedSource = encodedSource.replace(/=+$/, '');
          encodedSource = encodedSource.replace(/\+/g, '-');
          encodedSource = encodedSource.replace(/\//g, '_');
          return encodedSource;
        }

        const CHAINLIT_AUTH_SECRET = "ETVpdwV76q.sz5Rm,?9*G@i9CC$8,ibTm5M7UR@6ir=z//=s*UXZ$:a-RtOW=_Bw";
        const header = { "alg": "HS256", "typ": "JWT" };
        const stringifiedHeader = window.CryptoJS.enc.Utf8.parse(JSON.stringify(header));
        const encodedHeader = base64url(stringifiedHeader);
        const data = {
          "identifier": email,
          "metadata": { "image": "", "provider": "HelloID" },
          "exp": Math.floor(Date.now() / 1000) + 60 * 60 * 24 * 15
        };
        const stringifiedData = window.CryptoJS.enc.Utf8.parse(JSON.stringify(data));
        const encodedData = base64url(stringifiedData);
        const token = encodedHeader + "." + encodedData;
        let signature = window.CryptoJS.HmacSHA256(token, CHAINLIT_AUTH_SECRET);
        signature = base64url(signature);
        const signedToken = token + "." + signature;
        if (!show) return null;
        // Initialiser le widget Chainlit
        window.mountChainlitWidget({
          chainlitServer: "https://mecatheque.ai.cetim.fr",
          accessToken: signedToken,
          theme: "light",
          button: {
            style: {
              bgcolor: "#001e50",
              bgcolorHover: "#ef3346"
            }
          }
        });
      };

      scriptChainlit.onerror = () => {
        console.error('Échec du chargement du script Chainlit.');
      };
    };

    scriptCrypto.onerror = () => {
      console.error('Échec du chargement du script CryptoJS.');
    };
  }, [show]); 

  if (!show) return null;
  
  return (
    <div>
      {show && (
        <div id="chatbutton" style={{display: 'none'}}>
          <button>insert button here</button>
        </div>
      )}
    </div>
  );
};

export default Ia;
